import { Colors } from './types';

export const colors: Colors = {
    primary: '#2CBB00',
    secondary: '#e4e6eb',

    // Components
    wildSand: '#F6F6F6',
    white: '#fff',
    black: '#000',
    iceberg: '#E2F6F5',
    listItemTapColor: '#d8d7dc',
    red2: 'rgb(249,54,54)',
    lightRed2: 'rgba(229, 84, 75, 0.1)',
    whiteRgba: 'rgba(255, 255, 255, 0.9)'
};